import { LogoImage } from "@/assets/images";
import { Box, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const Footer = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Box sx={BoxStyle(theme)}>
      <Box sx={InnerBoxStyle}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {mapData?.map((item, index) => (
            <Link
              key={index}
              to={item?.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                height: "24px",
                width: "24px",
                marginRight: index !== mapData?.length - 1 ? "24px" : 0,
                cursor: "pointer",
                display: "inline-block",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  "& svg path": {
                    transition: "fill 0.3s ease",
                  },
                  "&:hover svg path[fill='#667085']": {
                    fill: item.color,
                  },
                }}
              >
                {item?.imgSrc}
              </Box>
            </Link>
          ))}
        </Box>
        <Box sx={LogoBoxStyle}>
          <img
            loading="lazy"
            src={LogoImage}
            alt="logo"
            style={{ height: "100%", width: "100%", cursor: "pointer" }}
            // onClick={() => {
            //   naviagte("/");
            // }}
          />
        </Box>
        <Typography variant="body1">
          {t("LandingPage.All_rights_reserved")}
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
const BoxStyle = (theme) => ({
  bgcolor: theme?.palette?.background?.default,
  minHeight: "80px",
  width: "100%",
  px: { xs: "1rem", sm: "3rem", md: "6rem", lg: "7rem" },
  py: "36px",
});

const LogoBoxStyle = {
  height: { xs: "18.2px", sm: "23.4px", md: "26px" },
  width: { xs: "89.6px", sm: "115.2px", md: "128px" },
  my: { xs: "24px", sm: "0" },
};

const InnerBoxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: { xs: "column", sm: "row" },
};

const mapData = [
  {
    imgSrc: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_12)">
          <mask
            id="mask0_1_12"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="24"
            height="24"
          >
            <path d="M24 0H0V24H24V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_1_12)">
            <path
              d="M21.1875 0H2.8125C1.2592 0 0 1.2592 0 2.8125V21.1875C0 22.7408 1.2592 24 2.8125 24H21.1875C22.7408 24 24 22.7408 24 21.1875V2.8125C24 1.2592 22.7408 0 21.1875 0Z"
              fill="#667085"
            />
            <path
              d="M16.683 4.6875H19.164L13.7438 10.8825L20.1202 19.3125H15.1275L11.217 14.1998L6.7425 19.3125H4.26002L10.0575 12.6863L3.94052 4.6875H9.06L12.5947 9.36075L16.683 4.6875ZM15.8122 17.8275H17.187L8.313 6.0945H6.83775L15.8122 17.8275Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_1_12">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    ),
    link: "https://x.com/Big_Entity",
    title: "twitter",
    color: "#000", // Twitter's hover color (you can adjust this)
  },

  {
    imgSrc: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.2234 0H1.77187C0.792187 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.792187 24 1.77187 24H22.2234C23.2031 24 24 23.2219 24 22.2703V1.72969C24 0.773438 23.2031 0 22.2234 0ZM7.12031 20.4516H3.55781V8.99531H7.12031V20.4516ZM5.33906 7.43438C4.19531 7.43438 3.27188 6.51094 3.27188 5.37187C3.27188 4.23281 4.19531 3.30937 5.33906 3.30937C6.47813 3.30937 7.40156 4.23281 7.40156 5.37187C7.40156 6.50625 6.47813 7.43438 5.33906 7.43438ZM20.4516 20.4516H16.8937V14.8828C16.8937 13.5562 16.8703 11.8453 15.0422 11.8453C13.1906 11.8453 12.9094 13.2937 12.9094 14.7891V20.4516H9.35625V8.99531H12.7687V10.5609H12.8156C13.2891 9.66094 14.4516 8.70938 16.1813 8.70938C19.7859 8.70938 20.4516 11.0813 20.4516 14.1656V20.4516Z"
          fill="#667085"
        />
      </svg>
    ),
    link: "https://www.linkedin.com/company/icod-ai",
    title: "linkedin",
    color: "#0077B5", // LinkedIn's primary color
  },
  {
    imgSrc: (
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 17.9895 4.3882 22.954 10.125 23.8542V15.4688H7.07812V12H10.125V9.35625C10.125 6.34875 11.9166 4.6875 14.6576 4.6875C15.9701 4.6875 17.3438 4.92188 17.3438 4.92188V7.875H15.8306C14.34 7.875 13.875 8.80008 13.875 9.75V12H17.2031L16.6711 15.4688H13.875V23.8542C19.6118 22.954 24 17.9895 24 12Z"
          fill="#667085"
        />
      </svg>
    ),
    link: "https://www.facebook.com/profile.php?id=61570027096405",
    title: "facebook",
    color: "#1877F2", // Facebook's primary color
  },
  {
    imgSrc: (
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3719_21347)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 0.833496C5.3724 0.833496 0 6.2143 0 12.8539C0 18.1639 3.438 22.6699 8.2068 24.2587C8.8068 24.3691 9.0252 23.9983 9.0252 23.6791C9.0252 23.3947 9.0156 22.6375 9.0096 21.6355C5.6712 22.3615 4.9668 20.0239 4.9668 20.0239C4.422 18.6343 3.6348 18.2647 3.6348 18.2647C2.5452 17.5207 3.7176 17.5351 3.7176 17.5351C4.9212 17.6191 5.5548 18.7735 5.5548 18.7735C6.6252 20.6095 8.364 20.0791 9.0468 19.7719C9.1572 18.9955 9.4668 18.4663 9.81 18.1663C7.146 17.8627 4.344 16.8307 4.344 12.2251C4.344 10.9135 4.812 9.8395 5.5788 8.9995C5.4552 8.6959 5.0436 7.4731 5.6964 5.8195C5.6964 5.8195 6.7044 5.4955 8.9964 7.0507C9.97532 6.78371 10.9853 6.64773 12 6.6463C13.02 6.6511 14.046 6.7843 15.0048 7.0507C17.2956 5.4955 18.3012 5.8183 18.3012 5.8183C18.9564 7.4731 18.5436 8.6959 18.4212 8.9995C19.1892 9.8395 19.6548 10.9135 19.6548 12.2251C19.6548 16.8427 16.848 17.8591 14.1756 18.1567C14.6064 18.5275 14.9892 19.2607 14.9892 20.3827C14.9892 21.9883 14.9748 23.2855 14.9748 23.6791C14.9748 24.0007 15.1908 24.3751 15.8004 24.2575C18.19 23.456 20.2672 21.9239 21.7386 19.8776C23.2099 17.8312 24.001 15.3743 24 12.8539C24 6.2143 18.6264 0.833496 12 0.833496Z"
            fill="#667085"
          />
        </g>
        <defs>
          <clipPath id="clip0_3719_21347">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0 0.833496)"
            />
          </clipPath>
        </defs>
      </svg>
    ),
    link: "https://www.linkedin.com/company/bigentities/",
    title: "github",
    color: "#000", // Facebook's primary color
  },
];
