import { GuestProfileImage } from "@/assets/images";
import { useAppSelector } from "@/redux/store";
import { Avatar, Box, Button, Tooltip, Typography } from "@mui/material";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

const AvatarText = () => {
  const navigate = useNavigate();
  const ProfileData = useAppSelector((state) => state.user.user);
  const { loading } = useAppSelector((state) => state.prompt);
  const location = useLocation();
  return (
    <Tooltip
      title={
        <div>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: "500px", color: "#fff" }}
          >
            {ProfileData?.fullName}
          </Typography>
          <Typography variant="subtitle1" sx={{ color: "#fff" }}>
            {ProfileData?.email}
          </Typography>
        </div>
      }
    >
      <Box
        sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
        onClick={() => {
          if (location.pathname === "/create-website" && loading) {
            toast(
              (t) => (
                <span>
                  Please stay on this page while we process your request. Once
                  we receive the response, we will navigate you to the preview
                  screen.
                  <br />
                  <br />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      variant="contained"
                      onClick={() => toast.dismiss(t.id)}
                    >
                      Close
                    </Button>
                    <Button
                      sx={{ ml: "12px" }}
                      onClick={() => {
                        navigate(
                          ProfileData?.type === "guest"
                            ? "/my-projects"
                            : "/profile"
                        );
                        toast.dismiss(t.id);
                      }}
                    >
                      Go anyway
                    </Button>
                  </div>
                </span>
              ),
              {
                duration: 5000,
                style: {
                  minWidth: "250px",
                  maxWidth: "450px",
                },
              }
            );
          } else {
            navigate(
              ProfileData?.type === "guest" ? "/my-projects" : "/profile"
            );
          }
        }}
      >
        <Box>
          {ProfileData?.type === "guest" ? (
            <Avatar sx={{ width: 32, height: 32 }}>
              <img
                src={GuestProfileImage}
                alt="Guest Profile"
                width="100%"
                height="100%"
              />
            </Avatar>
          ) : ProfileData?.profileImage ? (
            <Avatar sx={{ width: 36, height: 36 }}>
              <img
                src={ProfileData?.profileImage}
                alt="profile"
                width="100%"
                height="100%"
              />
            </Avatar>
          ) : (
            <Avatar
              sx={{
                width: 36,
                height: 36,
                background:
                  location.pathname === "/create-website" ? "#fff" : "#7C4CE1",
                color:
                  location.pathname === "/create-website" ? "#7C4CE1" : "#fff",
              }}
            >
              {" "}
              {ProfileData?.fullName[0]?.toUpperCase() || "F"}
            </Avatar>
          )}
        </Box>
        <Box sx={{ ml: "8px", display: { xs: "none", sm: "block" } }}>
          <Typography
            variant="subtitle1"
            sx={{
              fontWeight: "500px",
              color:
                location.pathname === "/create-website" ? "#ffffff" : "#1E293B",
              width: "120px",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {ProfileData?.fullName}
          </Typography>
          {/* 
          <Typography
            variant="subtitle1"
            sx={{
              color:
                location.pathname === "/create-website" ? "#ffffff" : "#667085",
              width: "120px",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {ProfileData?.email}
          </Typography> */}
        </Box>
      </Box>
    </Tooltip>
  );
};

export default AvatarText;
