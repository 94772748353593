import { LogoWithOutTextImage } from "@/assets/images";
import LanguageDropdown from "@/components/languageDropdown";
import AiAssistant from "@/layout/navbar/components/menu/AiAssistant";
import ColorMenu from "@/layout/navbar/components/menu/ColorMenu";
import FontFamilyMenu from "@/layout/navbar/components/menu/FontFamilyMenu";
import ThemeMenu from "@/layout/navbar/components/menu/ThemeMenu";
import ProfileModel from "@/layout/navbar/components/Model";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const EditorLayout = () => {
  return (
    <Box sx={BoxSidebarStyle}>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box sx={BoxLogoStyle}>
          <Link
            to="/my-projects"
            style={{
              display: "block",
              height: "100%",
              width: "100%",
            }}
          >
            <img
              src={LogoWithOutTextImage}
              alt="logo"
              style={{ height: "100%", width: "100%" }}
            />
          </Link>
        </Box>
        <Box sx={BoxToolsStyle}>
          <FontFamilyMenu />
          <ColorMenu />
          <ThemeMenu />
          <AiAssistant />
        </Box>
      </Box>
      <Box sx={BoxStyle}>
        <LanguageDropdown />
        <ProfileModel />
      </Box>
    </Box>
  );
};

export default EditorLayout;

const BoxSidebarStyle = {
  width: "60px",
  bgcolor: "#FFFFFF",
  flexDirection: "column",
  display: "flex",
  justifyContent: "space-between",
};
const BoxLogoStyle = {
  height: "34px",
  width: "32px",
  mx: "auto",
  my: "16px",
  cursor: "pointer",
};
const BoxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};
const BoxToolsStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};
