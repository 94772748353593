import { Box, Button } from "@mui/material";
import { useState } from "react";
import {
  CodeImageDarkIcon,
  CodeImageIcon,
  StyleImageDarkIcon,
  StyleImageIcon,
} from "@/assets/images";
import { useTranslation } from "react-i18next";

const CanvasCode = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [value, setValue] = useState(0);
  const { t } = useTranslation();
  return (
    <Box sx={BoxStyle}>
      {Btnmap?.map((item, index) => (
        <Button
          key={index}
          variant="contained"
          sx={btnBoxStyle(index, value)}
          onClick={() => {
            setValue(item.id);
          }}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          {t(item?.name)}
          {
            <img
              src={
                index === value || hoveredIndex === index
                  ? item?.imgSrcDark
                  : item.imgSrc
              }
              alt={item.name}
              className="canvasCodeStyle"
              style={{
                height: "16px",
                width: "16px",
                marginLeft: "4px",
              }}
            />
          }
        </Button>
      ))}
    </Box>
  );
};

export default CanvasCode;

const BoxStyle = {
  bgcolor: "#E2E8F0",
  border: "1px solid #E2E8F0",
  borderRadius: "28px",
  padding: "4px",
  mb: { xs: ".6rem", sm: "0" },
};

const Btnmap = [
  {
    imgSrc: StyleImageIcon,
    imgSrcDark: StyleImageDarkIcon,
    name: "Header.Canvas",
    id: 0,
  },
  {
    imgSrc: CodeImageIcon,
    imgSrcDark: CodeImageDarkIcon,
    name: "Header.Code",
    id: 1,
  },
];

const btnBoxStyle = (index, value) => ({
  boxShadow: "none",
  color: index === value ? "#7C4CE1" : "#000",
  backgroundColor: index === value ? "#FFFFFF" : "transparent",
  height: "32px",
  width: "6rem",
  borderRadius: "28px",
  transition: "all .4s",
  "&:hover": {
    color: "#7C4CE1",
    backgroundColor: "#FFFFFF",
  },
  mr: index === 0 ? ".4rem" : "0",
});
