import { useMemo, ReactElement, ReactNode } from "react";
// @mui
import {
  Box,
  createTheme,
  CssBaseline,
  PaletteMode,
  StyledEngineProvider,
  Theme,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material";

import palette from "./palette";
import typography from "./typography";
import GlobalStyles from "./globalStyles";
import componentsOverride from "./overrides";

// ----------------------------------------------------------------------

interface ThemeProviderProps {
  children: ReactNode;
  direction?: "ltr" | "rtl";
  mode: PaletteMode;
}

// Remove defaultProps usage
export default function ThemeProvider({
  children,
  mode,
  direction = "ltr", // Default value for direction
}: ThemeProviderProps): ReactElement {
  const themeOptions = useMemo(
    () => ({
      palette: palette(mode),
      shape: { borderRadius: 6 },
      typography: typography(mode, direction),
    }),
    [mode, direction]
  );
  

  const theme: Theme = createTheme(themeOptions as any);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <Box dir={direction}> {children} </Box>
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
