import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  setFullStackOptions,
  setFullStackTools,
} from "@/redux/slices/toolsSlice";
import "./FontFamily.module.css";

export default function FullStackChatBot() {
  const dispatch = useAppDispatch();
  const { fullStackOptions } = useAppSelector((state) => state.tools);

  const handleClick = () => {
    dispatch(setFullStackTools());
    dispatch(setFullStackOptions({ key: "chatbot", value: true }));
  };

  return (
    <Box>
      <Box
        onClick={handleClick}
        sx={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "6px",
          borderRadius: "8px",
          height: "48px",
          width: "48px",
          backgroundColor: fullStackOptions["chatbot"]
            ? "#CED4DA"
            : "transparent",
          ":hover": { backgroundColor: "#CED4DA" },
          transition: "all .3s",
        }}
      >
        <IconSvg
          fillColor={fullStackOptions["chatbot"] ? "#7C4CE1" : "#374151"}
        />
      </Box>
    </Box>
  );
}

const IconSvg = ({ fillColor }: { fillColor: string }) => (
  <svg
    width="32"
    height="24"
    viewBox="0 0 32 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9913 0.57518C8.66047 0.57518 2.71799 5.68871 2.71799 11.9963C2.71799 15.2464 4.29559 18.1792 6.82777 20.2589C6.79541 21.2506 6.44934 22.2321 5.84415 23.0191C7.01743 22.9724 8.16673 22.5049 9.05045 21.7331C11.07 22.8004 13.4472 23.4173 15.9914 23.4173C23.3222 23.4173 29.265 18.3039 29.265 11.9963C29.265 5.68871 23.322 0.57518 15.9913 0.57518ZM24.2411 17.5306C23.9984 17.7967 23.7027 18.009 23.3731 18.1541C23.0435 18.2991 22.6872 18.3737 22.327 18.3729H9.65564C9.29551 18.3737 8.93921 18.2991 8.60958 18.1541C8.27996 18.009 7.9843 17.7967 7.74159 17.5306C6.35643 16.0043 5.53098 14.0839 5.53098 11.9963C5.53098 9.90872 6.35626 7.98854 7.74141 6.46203C7.98414 6.19595 8.27982 5.98358 8.60948 5.83853C8.93914 5.69348 9.29548 5.61897 9.65564 5.61977H22.327C22.6913 5.62012 23.0515 5.69693 23.3842 5.84524C23.7169 5.99355 24.0148 6.21005 24.2586 6.48075C25.6334 8.00429 26.4521 9.91799 26.4521 11.9963C26.4521 14.0832 25.6264 16.0043 24.2413 17.5306H24.2411Z"
      fill={fillColor}
    />
    <path
      d="M2.08586 11.9963C2.08586 13.5629 2.42546 15.0821 3.09625 16.5173C1.30414 15.928 0 14.7616 0 11.9963C0 9.37317 1.30414 8.06447 3.09677 7.47521C2.42581 8.91128 2.08586 10.4311 2.08586 11.9963Z"
      fill={fillColor}
    />
    <path
      d="M1.54821 4.08469V7.50863C1.09514 7.79761 0.696796 8.1645 0.371613 8.59233L0.363214 8.60353V4.08469C0.365885 3.92931 0.429482 3.7812 0.540307 3.67226C0.651131 3.56332 0.800313 3.50228 0.955713 3.50228C1.11111 3.50228 1.26029 3.56332 1.37112 3.67226C1.48194 3.7812 1.54554 3.92931 1.54821 4.08469Z"
      fill={fillColor}
    />
    <path
      d="M29.8972 11.9963C29.8972 13.5629 29.5576 15.0821 28.8868 16.5173C30.6789 15.928 31.9836 14.7616 31.9836 11.9963C31.9836 9.37317 30.6796 8.06447 28.8868 7.47521C29.5572 8.91128 29.8972 10.4311 29.8972 11.9963Z"
      fill={fillColor}
    />
    <path
      d="M30.4346 4.08469V7.50863C30.8877 7.79761 31.2861 8.1645 31.6112 8.59233C31.614 8.596 31.617 8.59968 31.6196 8.60353V4.08469C31.617 3.92931 31.5534 3.7812 31.4425 3.67226C31.3317 3.56332 31.1825 3.50228 31.0271 3.50228C30.8717 3.50228 30.7226 3.56332 30.6117 3.67226C30.5009 3.7812 30.4373 3.92931 30.4346 4.08469Z"
      fill={fillColor}
    />
    <path
      d="M13.1707 11.642C12.7858 12.2358 12.2288 11.642 11.0665 11.642C9.90426 11.642 9.34789 12.2358 8.96228 11.642C8.58909 11.0675 9.6189 9.6496 11.0665 9.6496C12.5141 9.6496 13.5439 11.0675 13.1707 11.642Z"
      fill={fillColor}
    />
    <path
      d="M23.0208 11.642C22.6358 12.2358 22.0788 11.642 20.9163 11.642C19.7539 11.642 19.1977 12.2358 18.8123 11.642C18.4389 11.0675 19.4687 9.6496 20.9163 9.6496C22.364 9.6496 23.3938 11.0675 23.0208 11.642Z"
      fill={fillColor}
    />
    <path
      d="M15.9913 14.3429C15.18 14.3429 14.6441 13.6997 14.6224 13.6723C14.5935 13.637 14.5719 13.5964 14.5588 13.5527C14.5456 13.5091 14.5412 13.4633 14.5457 13.418C14.5549 13.3264 14.6001 13.2422 14.6714 13.1839C14.7426 13.1257 14.8341 13.0981 14.9257 13.1073C15.0172 13.1165 15.1014 13.1617 15.1597 13.2329C15.1619 13.2356 15.5134 13.6488 15.9913 13.6488C16.4691 13.6488 16.8204 13.2356 16.8239 13.2315C16.8528 13.1963 16.8884 13.1672 16.9286 13.1457C16.9688 13.1243 17.0129 13.111 17.0582 13.1065C17.1036 13.1021 17.1493 13.1067 17.1929 13.1199C17.2365 13.1332 17.2771 13.1549 17.3123 13.1839C17.3475 13.2128 17.3766 13.2484 17.3981 13.2886C17.4195 13.3288 17.4328 13.3728 17.4373 13.4182C17.4417 13.4635 17.4371 13.5093 17.4239 13.5529C17.4106 13.5965 17.3889 13.6371 17.36 13.6723C17.3384 13.6997 16.8025 14.3429 15.9913 14.3429Z"
      fill={fillColor}
    />
  </svg>
);
