import PrimaryLoading from "@/components/loader/PrimaryLoading";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  collaboratorResendInvite,
  collaboratorRoleUpdate,
  getCollaboratorsList,
} from "@/redux/thunk/collaboratorThunk";
import {
  Box,
  Button,
  FormControl,
  List,
  ListItem,
  MenuItem,
  Select,
  SelectChangeEvent,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import toast from "react-hot-toast";
import CommentDialog from "../../comments/DialogComments";
import { useTranslation } from "react-i18next";

interface CustomListProps {
  emailValue: string;
  errorsValue: string;
  id: string;
}

const CustomList: React.FC<CustomListProps> = (props) => {
  const { emailValue, errorsValue, id } = props;
  const dispatch = useAppDispatch();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [collaboratorId, setCollaboratorId] = React.useState("");
  const { loading, collaboratorList, isLoading } = useAppSelector(
    (state) => state.collaborator
  );
  const { t } = useTranslation();
  const handleCloseDilog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      {" "}
      {!(emailValue && !errorsValue) && (
        <Box>
          {collaboratorList?.length > 0 && (
            <Typography variant="h5">
              {t("utalities.People_with_access")}
            </Typography>
          )}
          {loading ? (
            <Box
              sx={{
                maxHeight: "160px",
                minHeight: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <PrimaryLoading />
            </Box>
          ) : (
            <List
              sx={{
                maxHeight: "160px",
                overflowY: "auto",
              }}
            >
              {collaboratorList?.map((item, index) => (
                <ListItem key={index} disablePadding sx={{ p: "6px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: { xs: "flex-start", sm: "center" },
                      justifyContent: "space-between",
                      flexDirection: { xs: "column", sm: "row" },
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "flex-end",
                      }}
                    >
                      <Box>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: 600, lineHeight: 1 }}
                        >
                          {item?.userId?.fullName}
                        </Typography>
                        <Typography
                          variant="body1"
                          sx={{
                            fontSize: {
                              xs: "12px",
                              sm: "13px",
                              md: "14px",
                            },
                          }}
                        >
                          {item?.email}
                        </Typography>
                      </Box>
                    </Box>
                    {item?.status === "pending" ? (
                      <Button
                        variant="outlined"
                        sx={PreviewButtonStyle}
                        onClick={() => {
                          setOpenDialog(true);
                          setCollaboratorId(item?._id);
                        }}
                      >
                        {t("Forget_Password.Resend")}
                      </Button>
                    ) : (
                      <FormControl
                        sx={{ minWidth: { xs: "100px", sm: "120px" } }}
                      >
                        <CustomSelect
                          value={item?.role}
                          onChange={async (event: SelectChangeEvent) => {
                            const resultAction = await dispatch(
                              collaboratorRoleUpdate({
                                role: event.target.value,
                                collaboratorId: item?._id,
                                projectId: id,
                              })
                            );
                            if (
                              collaboratorRoleUpdate.fulfilled.match(
                                resultAction
                              )
                            ) {
                              toast.success(
                                resultAction?.payload?.message ||
                                  "Role updated successfully"
                              );
                              dispatch(getCollaboratorsList({ projectId: id }));
                            } else if (
                              collaboratorRoleUpdate.rejected.match(
                                resultAction
                              )
                            ) {
                              toast.error(
                                resultAction?.payload?.reason ||
                                  "something went wronge"
                              );
                            }
                          }}
                          displayEmpty
                          sx={{
                            padding: "0",
                            height: "38px",
                            "&:hover": {
                              borderColor: "#7c4ddd",
                            },
                          }}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {MapData.map((item, index) => (
                            <MenuItem
                              key={index}
                              sx={{ height: "32px" }}
                              value={item?.value}
                            >
                              {item?.name}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </FormControl>
                    )}
                  </Box>
                </ListItem>
              ))}
            </List>
          )}
          <CommentDialog
            open={openDialog}
            handleClose={handleCloseDilog}
            deleteFlag={false}
            isLoading={isLoading}
            BtnText="Resend Email"
            CardText="Would you like to resend the project collaboration invite? Please confirm if you'd like to proceed."
            handleSubmit={async () => {
              const resultAction = await dispatch(
                collaboratorResendInvite({
                  collaboratorId: collaboratorId,
                  projectId: id,
                })
              );
              if (collaboratorResendInvite.fulfilled.match(resultAction)) {
                toast.success(
                  resultAction?.payload?.message ||
                    "Comment deleted successfully."
                );
                setCollaboratorId("");
                setOpenDialog(false);
              } else if (
                collaboratorResendInvite.rejected.match(resultAction)
              ) {
                toast.error(
                  resultAction?.payload?.reason || "Something went wronge."
                );
              }
            }}
          />
        </Box>
      )}
    </>
  );
};

export default CustomList;

export const CustomSelect = styled(Select)(() => ({
  "&.MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "#7E4FE2",
    },
  },
}));

const MapData = [
  { name: "Admin", value: "admin" },
  { name: "Editor", value: "editor" },
  { name: "Viewer", value: "viewer" },
];
const PreviewButtonStyle = {
  borderColor: "#fff",
  display: { xs: "none", md: "flex" },
  mx: "8px",
  border: "2px solid #7C4CE1",
  color: "#7C4CE1",
};
