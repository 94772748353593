import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
  AutoSaveChangesIcon,
  ExportButtonIcon,
  LogoImage,
  UnSavedIcons,
  WhiteLogoIcon,
} from "@/assets/images";
import { useNavigate } from "react-router-dom";
import CanvasCode from "./components/CanvasCode";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useEffect } from "react";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import ToolSidebar from "./components/ToolSidebar";
import {
  setProductionModel,
  setPrototypeModel,
} from "@/redux/slices/userSlice";
import { useAppDispatch, useAppSelector } from "@/redux/store";
// import Collaborators from "./components/collaborators";
import PlayArrowOutlinedIcon from "@mui/icons-material/PlayArrowOutlined";
import ResponsivenessComp from "@/pages/siteBuilder/components/ResponsivessComp";
import Collaborators from "./components/collaborators";
import {
  setOpenGlobalFontFamily,
  setOpenGlobalPallets,
  setOpenGlobalTheme,
  setSaveCode,
} from "@/redux/slices/toolsSlice";
import UpdateProjectName from "./components/UpdateProjectName";
import AvatarText from "./components/AvatarText";
import SelectAppFilter from "./components/SelectAppFilter";
import { useTranslation } from "react-i18next";
import { clearChat } from "@/redux/slices/chatbotSlice";
import toast from "react-hot-toast";

interface PrivateNavbarProps {
  location: any;
}

const PrivateNavbar: React.FC<PrivateNavbarProps> = (props) => {
  const { location } = props;
  const naviagte = useNavigate();
  const theme = useTheme(); // Access the theme for consistency
  const isSmallScreen = useMediaQuery("(min-width:786px)");
  const [showSaving, setShowSaving] = React.useState(true);
  const [display, setDisplay] = React.useState(false);
  const [displayDashboard, setDisplayDashboard] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { projectRole, saveProjectStatus, saveProjectLoader, loading } =
    useAppSelector((state) => state.prompt);
  const { prototypeModel } = useAppSelector((state) => state.user);
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };
  useEffect(() => {
    if (
      location.pathname.startsWith("/icod-builder") ||
      location.pathname.startsWith("/builder-webApp")
    ) {
      setDisplay(true);
      setDisplayDashboard(false);
    } else if (
      location.pathname === "/my-projects" ||
      location.pathname === "/profile" ||
      location.pathname === "/shared-projects"
    ) {
      setDisplayDashboard(true);
      setDisplay(false);
      dispatch(clearChat());
    } else {
      setDisplay(false);
      setDisplayDashboard(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (saveProjectStatus === true) {
      const timer = setTimeout(() => {
        setShowSaving(false);
      }, 2000); // Hide after 2 seconds
      return () => clearTimeout(timer); // Cleanup timer on unmount or on dependency change
    }
  }, [saveProjectStatus]);
  const handleAnchorClick = (event) => {
    event.preventDefault(); // Prevent default navigation
    const targetHref =
      location.pathname === "/create-website" ? "/my-projects" : "/";

    toast(
      (t) => (
        <span>
          Please stay on this page while we process your request. Once we
          receive the response, we will navigate you to the preview screen.
          <br />
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button variant="contained" onClick={() => toast.dismiss(t.id)}>
              Close
            </Button>
            <Button
              sx={{ ml: "12px" }}
              onClick={() => {
                toast.dismiss(t.id);
                naviagte(targetHref);
              }}
            >
              Go anyway
            </Button>
          </div>
        </span>
      ),
      {
        duration: 5000,
        style: {
          minWidth: "250px",
          maxWidth: "450px",
        },
      }
    );
  };

  return (
    <Box sx={FirstBoxStyle(location, theme)}>
      <Box sx={ContainerStyle}>
        <Box sx={BoxStyle}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {location.pathname.startsWith("/icod-builder") ||
            location.pathname.startsWith("/builder-webApp") ||
            displayDashboard ? null : (
              <Box
                sx={{
                  height: { xs: "18.2px", sm: "23.4px", md: "26px" },
                  width: { xs: "89.6px", sm: "115.2px", md: "128px" },
                }}
              >
                <a
                  style={{
                    display: "block",
                    height: "100%",
                    width: "100%",
                    cursor: "pointer",
                  }}
                  onClick={(event) => {
                    if (event.metaKey || event.ctrlKey) {
                      window.open(window.location.href, "_blank");
                      return;
                    }
                    if (location.pathname === "/create-website" && loading) {
                      handleAnchorClick(event);
                    } else {
                      // If not on create-website page or not loading, proceed with normal navigation
                      window.location.href =
                        location.pathname === "/create-website"
                          ? "/my-projects"
                          : "/";
                    }
                  }}
                >
                  <img
                    src={
                      location.pathname === "/create-website"
                        ? WhiteLogoIcon
                        : LogoImage
                    }
                    alt="logo"
                    style={{ height: "100%", width: "100%" }}
                  />
                </a>
              </Box>
            )}

            {(display || displayDashboard) && (
              <Box
                sx={{
                  display: { xs: "block", md: "none" },
                  height: "24px",
                  width: "24px",
                  mr: "8px",
                }}
              >
                <MenuIcon
                  sx={{ color: "#7C4CE1" }}
                  onClick={toggleDrawer(true)}
                />
              </Box>
            )}
            {/* Show Name of the project  */}
            {location.pathname.startsWith("/my-projects") && (
              <Typography variant="h4">
                {" "}
                {t("Dashboard.My_Projects")}
              </Typography>
            )}
            {location.pathname.startsWith("/shared-projects") && (
              <Typography variant="h4">
                {" "}
                {t("Dashboard.Shared_Projects")}
              </Typography>
            )}
            {location.pathname.startsWith("/profile") && (
              <Typography variant="h4"> {t("Dashboard.Profile")}</Typography>
            )}

            {/* canvas */}
            {!location.pathname.startsWith("/builder-webApp") &&
              ["owner", "admin", "editor"].includes(projectRole) &&
              display &&
              !prototypeModel && (
                <Box
                  sx={{ display: { xs: "none", md: "block" } }}
                  className="icod-canvas-code-toolbar-controls"
                >
                  <CanvasCode />
                </Box>
              )}
            {/* Update Project Name */}
            {display && !prototypeModel && <UpdateProjectName />}
            {display && (
              <Box sx={{ ml: "12px", transition: "all .4s" }}>
                {saveProjectLoader ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Box
                      sx={{
                        width: "18px",
                        aspectRatio: "1", // Maintain a 1:1 aspect ratio
                        borderRadius: "50%",
                        border: "3px solid #D9D9D9",
                        borderRightColor: "#7a4ddf",
                        animation: "rotateAnimation 1s infinite linear",
                        "@keyframes rotateAnimation": {
                          to: {
                            transform: "rotate(1turn)", // Equivalent to 360deg
                          },
                        },
                      }}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "#1E293B", pt: "2.7px", ml: "6px" }}
                    >
                      {t("Header.Saving_changes")}
                    </Typography>
                  </Box>
                ) : saveProjectStatus && !saveProjectLoader ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      src={AutoSaveChangesIcon}
                      alt="save"
                      style={{ cursor: "pointer" }}
                    />
                    {showSaving && (
                      <Typography
                        variant="subtitle1"
                        sx={{ color: "#1E293B", pt: "2.7px", ml: "6px" }}
                      >
                        {t("Header.Changes_saved")}
                      </Typography>
                    )}
                  </Box>
                ) : (
                  <img
                    src={UnSavedIcons}
                    alt="save"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      dispatch(setSaveCode(true));
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
          {/* Responsiveness of the iframe */}
          {location.pathname.startsWith("/icod-builder") && (
            <ResponsivenessComp />
          )}
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {/* Collaboratoe and share */}
            {["owner", "admin"].includes(projectRole) &&
              isSmallScreen &&
              display &&
              !prototypeModel && <Collaborators />}

            {display && (
              <Button
                variant="outlined"
                startIcon={
                  <PlayArrowOutlinedIcon
                    sx={{ height: "24px", width: "24px" }}
                  />
                }
                sx={PreviewButtonStyle}
                onClick={() => dispatch(setPrototypeModel(true))}
              >
                {t("Header.Preview")}
              </Button>
            )}
            {["owner", "admin"].includes(projectRole) &&
              display &&
              !prototypeModel && (
                <Box className="icod-export-and-deploy-button">
                  <PrimaryButton
                    style={{ display: { xs: "none", md: "flex" } }}
                    compLeft={true}
                    text={t("Header.Export")}
                    onClick={() => {
                      dispatch(setProductionModel(true));
                      dispatch(setOpenGlobalFontFamily(false));
                      dispatch(setOpenGlobalPallets(false));
                      dispatch(setOpenGlobalTheme(false));
                    }}
                    imageComponent={
                      <img
                        src={ExportButtonIcon}
                        alt="export"
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    }
                  />
                </Box>
              )}
            {/* Avatar and email and user name */}
          </Box>
          {location?.pathname?.startsWith("/icod-builder") ||
          location.pathname.startsWith("/builder-webApp") ||
          displayDashboard ? null : (
            <AvatarText />
          )}
          {displayDashboard && !location?.pathname?.startsWith("/profile") && (
            <Box
              sx={{ display: { xs: "none", md: "flex" }, alignItems: "center" }}
            >
              <SelectAppFilter />
              <PrimaryButton
                style={{ display: "flex" }}
                compLeft={true}
                text={t("Header.CreateProject")}
                onClick={() => {
                  naviagte(`/create-website`);
                }}
                imageComponent={
                  <AddRoundedIcon
                    sx={{
                      marginRight: "8px",
                      height: "20px",
                      width: "20px",
                    }}
                  />
                }
              />
            </Box>
          )}
        </Box>
        <ToolSidebar open={open} toggleDrawer={toggleDrawer} />
      </Box>
    </Box>
  );
};

export default PrivateNavbar;

const ContainerStyle = {
  height: "54px",
  position: "sticky",
  top: 0,
  width: "100%",
};

const BoxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
  width: "100%",
  pr: "16px",
  pl: "12px",
};

const PreviewButtonStyle = {
  borderColor: "#fff",
  display: { xs: "none", md: "flex" },
  mx: "8px",
  border: "2px solid #7C4CE1",
  color: "#7C4CE1",
};
const FirstBoxStyle = (location, theme) => ({
  backgroundColor:
    location.pathname === "/create-website"
      ? "#7C4CE1"
      : theme?.palette?.background?.paper,
  borderBottom:
    location.pathname === "/create-website"
      ? "none"
      : `1px solid ${theme?.palette?.background?.neutral}`,
  zIndex: 1200,
  height: "54px",
  width: "100%",
  px:
    location.pathname === "/create-website"
      ? { xs: "0", sm: "6px", md: "12px" }
      : "0",
});
