import { Box, Divider, Drawer } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { DeployIcon, LogoImage, PrototypeIcon } from "@/assets/images";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import CanvasCode from "./CanvasCode";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { setPrototypeModel } from "@/redux/slices/userSlice";
import { useLocation } from "react-router-dom";
import DashboardMobileView from "./DashboardMobileView";
import React from "react";
import { useTranslation } from "react-i18next";
import Collaborators from "./collaborators";
import { setOpenExportModelMobile } from "@/redux/slices/toolsSlice";

interface SideBarProps {
  toggleDrawer: (
    open: boolean
  ) => (event: React.KeyboardEvent | React.MouseEvent) => void;
  open: boolean;
}

const ToolSidebar: React.FC<SideBarProps> = (props) => {
  const { t } = useTranslation();
  const { toggleDrawer, open } = props;
  const { projectRole } = useAppSelector((state) => state.prompt);
  const location = useLocation();
  const dispatch = useAppDispatch();

  const DrawerList = (
    <Box sx={{ width: 270, height: "100%" }} role="presentation">
      <Box sx={{ height: "8%", p: "18px 12px 9px 12px" }}>
        <Box sx={boxStyle}>
          <Box sx={{ height: "20px" }}>
            <img
              src={LogoImage}
              alt="logo"
              style={{ height: "100%", width: "100%" }}
            />
          </Box>
          <CloseIcon sx={{ cursor: "pointer" }} onClick={toggleDrawer(false)} />
        </Box>
      </Box>
      <Divider />
      {location.pathname === "/my-projects" ||
      location.pathname === "/profile" ||
      location.pathname === "/shared-projects" ? (
        <DashboardMobileView toggleDrawer={toggleDrawer(false)} />
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: "10px",
            }}
          >
            {["owner", "admin", "editor"].includes(projectRole) && (
              <CanvasCode />
            )}
            {["owner", "admin"].includes(projectRole) && (
              <PrimaryButton
                style={{ my: "12px" }}
                compLeft={true}
                text="Export/Deploy"
                onClick={() => dispatch(setOpenExportModelMobile(true))}
                imageComponent={
                  <img
                    src={DeployIcon}
                    alt="export"
                    style={{
                      marginRight: "8px",
                      height: "16px",
                      width: "16px",
                    }}
                  />
                }
              />
            )}
            <Box
              sx={ImageBoxStyle}
              onClick={() => dispatch(setPrototypeModel(true))}
            >
              <img
                src={PrototypeIcon}
                alt="prototype"
                style={{ height: "12px", width: "12px", marginRight: "8px" }}
              />{" "}
              {t("utalities.Prototype")}
            </Box>

            {["owner", "admin"].includes(projectRole) && (
              <Box sx={{ my: "12px" }}>
                <Collaborators />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );

  return (
    <Drawer open={open} anchor={"left"} onClose={toggleDrawer(false)}>
      {DrawerList}
    </Drawer>
  );
};

export default ToolSidebar;

// styling

const boxStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const ImageBoxStyle = {
  border: "1px solid #7C4CE1",
  p: "6px",
  borderRadius: "7px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: " #7C4CE1",
  cursor: "pointer",
};
