import { getAssetUrl } from "@/utils/helpers.util";

// Exporting asset URLs
export const LogoWithOutTextImage = getAssetUrl(
  "1724752237834-logo-without-text.svg"
);

// Hero section
export const HeroCircleImage = getAssetUrl("1724752236401-hero-circle.svg");

// Dashboard section
export const musicPlayerAppImage = getAssetUrl(
  "1724752239883-musicPlayerApp.svg"
);
export const cookingAppImage = getAssetUrl("1724752230672-cookingApp.svg");
export const plannerAppImage = getAssetUrl("1724752241600-plannerApp.svg");
export const bookingAppImage = getAssetUrl("1724752227242-bookingApp.svg");

// Preview screen
export const codeIconImage = getAssetUrl("1724752229343-code.svg");
export const canvisIconImage = getAssetUrl("1724752228720-canvas_black.svg");
export const editToolImage = getAssetUrl("1724752235784-edittool.svg");

// Tools
export const TypographyIcon = getAssetUrl("1724752252057-typography.svg");
export const ColorsIcon = getAssetUrl("1724752245997-colors.svg");
export const ShapesIcon = getAssetUrl("1724752251046-shape.svg");
export const ScanIcon = getAssetUrl("1724752249815-scan.svg");
export const FilesIcon = getAssetUrl("1724752248076-file.svg");
export const LayoutIcon = getAssetUrl("1724752248614-layout.svg");
export const ThemeIcon = getAssetUrl("1724752251567-theme.svg");
export const PrototypeIcon = getAssetUrl("1724752249201-playbtn.svg");
export const DeployIcon = getAssetUrl("1724752247463-deploy.svg");
export const ArrowIcon = getAssetUrl("1724752245527-arrow.svg");
export const CommentIcon = getAssetUrl("1724752246744-comment.svg");

// Icons
export const sendIcon = getAssetUrl("1724752250461-sendIcon.svg");
export const DeployExportIcon = getAssetUrl("1724752234764-production.svg");
export const GithubExportIcon = getAssetUrl("1724752234182-github.svg");
export const CodeExportIcon = getAssetUrl("1724752233635-exportTheCode.svg");
export const ReactExportIcon = getAssetUrl("1724752232312-React.svg");
export const ReactCodeIcon = getAssetUrl("1724752231230-Code.svg");

export const NetlifyExportIcon = getAssetUrl("1724752231725-Netlify.svg");
export const VercelExportIcon = getAssetUrl("1724752232927-Vercel.svg");
export const whiteGithubExportIcon = getAssetUrl(
  "1724752235307-whiteGithub.svg"
);

// 404 not found image

export const noDataImage = getAssetUrl("1724752240502-noData.png");

// Static images for testing purposes
export const mobileImage = getAssetUrl("1724752243676-mobile1.svg");
export const mobile1Image = getAssetUrl("1724752244189-mobile2.svg");
export const TwitterIcon = getAssetUrl("1730393667005-twitter.svg");
export const ThreeStarIcon = getAssetUrl("1730393665886-threeStar.svg");
export const StudentImage = getAssetUrl("1730393665369-studentImage.webp");
export const StarImage = getAssetUrl("1730393664792-star.png");
export const SendIconIcon = getAssetUrl("1730393664284-sendIcon.svg");
export const selectElementIcon = getAssetUrl("1730393663219-selectElement.svg");
export const ResendImage = getAssetUrl("1730393662592-reload.png");

export const imageLayersIcon = getAssetUrl("1730393656056-imageLayers.svg");
export const HtmlImage = getAssetUrl("1730393655432-htmlImage.png");
export const HeroSectionImageVedio = getAssetUrl(
  "1730393653424-herosectionvedio.webp"
);
export const GithubIcon = getAssetUrl("1730393651554-github.svg");
export const GenerateToAppIcon = getAssetUrl(
  "1730393650928-generateToAppIcon.svg"
);
export const ExportButtonIcon = getAssetUrl("1730393650265-exportbtn.svg");
export const UnderlineStyle = getAssetUrl("1730393649683-underlineStyle.svg");
export const Span = getAssetUrl("1730393649187-span.svg");
export const AlignEnd = getAssetUrl("1730393648676-rightAlign.svg");
export const Paragraph = getAssetUrl("1730393648061-paragraph.svg");
export const AlignStart = getAssetUrl("1730393647507-leftAlign.svg");
export const AlignJustify = getAssetUrl("1730393646934-justifyAlign.svg");
export const ItalicStyle = getAssetUrl("1730393646352-italicStyle.svg");
export const HeadingOne = getAssetUrl("1730393645128-headingone.svg");
export const HeadingTwo = getAssetUrl("1730393645809-headingtwo.svg");
export const HeadingThree = getAssetUrl("1730393644073-headingThree.svg");
export const HeadingFour = getAssetUrl("1730393644613-headingfour.svg");
export const HeadingFive = getAssetUrl("1730393642319-headingFive.svg");
export const HeadingSix = getAssetUrl("1730393642946-headingSix.svg");
export const CenterLineStyle = getAssetUrl("1730393641817-centerLineStyle.svg");
export const AlignCenter = getAssetUrl("1730393641105-centerAlign.svg");
export const BoldStyle = getAssetUrl("1730393640549-BoldStyle.svg");
export const DesignerImage = getAssetUrl("1730393639804-designerimage.webp");
export const CustomPalletIcons = getAssetUrl("1730393639228-customPallet.svg");
export const containerLayersIcon = getAssetUrl(
  "1730393638748-containerLayers.svg"
);
export const ColorPickerIcons = getAssetUrl("1730393638231-colorPicker.svg");
export const chnagesViaCodeIcon = getAssetUrl(
  "1730393637528-chnagesViaCode.svg"
);
export const chnagesCommentIcon = getAssetUrl(
  "1730393636788-chnagesComment.svg"
);
export const changesViaEditorIcon = getAssetUrl(
  "1730393636295-changesViaEditor.svg"
);
export const cartCheckoutIcon = getAssetUrl("1730393635733-cartCheckout.svg");
export const adminDashboardIcon = getAssetUrl(
  "1730393635158-adminDashboard.svg"
);
export const WhoCanUseImage = getAssetUrl("1730393633768-Whocanuse.webp");
export const WebAppIcon = getAssetUrl("1730393632774-WebApp.svg");
export const WatchIcon = getAssetUrl("1730393632046-WatchIcon.svg");
export const UploadImageIcon = getAssetUrl("1730393631471-UploadImage.svg");
export const TickGreenIcon = getAssetUrl("1730393630859-TickGreen.svg");
export const ThemeImageIcon = getAssetUrl("1730393629765-ThemeImage.svg");
export const TabletScreenIcon = getAssetUrl("1730393629251-TabletScreen.svg");
export const StyleImageDarkIcon = getAssetUrl(
  "1730393628672-StyleImageDark.svg"
);
export const StyleImageIcon = getAssetUrl("1730393628193-StyleImage.svg");
export const StyleCustimizationIcon = getAssetUrl(
  "1730393627681-StyleCustimization.svg"
);
export const StarIconButton = getAssetUrl("1730393627169-StarIconButton.svg");
export const SolidStyleIcon = getAssetUrl("1730393626573-SolidStyle.svg");
export const SinglePageWebsiteIcon = getAssetUrl(
  "1730393625942-SinglePageWebsite.svg"
);
export const SectionLayers = getAssetUrl("1730393625225-SectionLayers.svg");
export const SecondBorderRadiousIcon = getAssetUrl(
  "1730393624713-SecondBorderRadious.svg"
);
export const SaveProjectNameIcons = getAssetUrl(
  "1730393624056-SaveProjectName.svg"
);
export const ReatTimeChattingIocn = getAssetUrl(
  "1730393617662-ReatTimeChatting.svg"
);
export const PromptGeneratedIcon = getAssetUrl(
  "1730393616933-PromptGenerated.svg"
);
export const ProjectNameIcon = getAssetUrl("1730393616325-ProjectName.svg");
export const ProjectExportedIcon = getAssetUrl(
  "1730393615600-ProjectExported.svg"
);
export const ProjectDetailsIcon = getAssetUrl(
  "1730393614988-ProjectDetails.svg"
);
export const RetroWebStyleImage = getAssetUrl(
  "1730393618219-RetroWebStyle.png"
);
export const PlatformSectionIcon = getAssetUrl(
  "1730393614478-PlatformSection.svg"
);
export const PaymentIntegratinIcon = getAssetUrl(
  "1730393613755-PaymentIntegratin.svg"
);
export const ParagraphLayersIcon = getAssetUrl(
  "1730393613223-ParagraphLayers.svg"
);
export const PalletColorIcon = getAssetUrl(
  "1730393612735-PalletColorImage.svg"
);
export const PageIcon = getAssetUrl("1730393612221-PageIcon.svg");
export const OpenEditorBtnIcons = getAssetUrl(
  "1730393611510-OpenEditorBtn.svg"
);
export const NotificationIcon = getAssetUrl("1730393610889-Notification.svg");
export const NoCodeIcon = getAssetUrl("1730393610277-NocodeIcon.svg");
export const NoStyleIcon = getAssetUrl("1730393609695-NoStyle.svg");
export const NoProjectMatchImage = getAssetUrl(
  "1730393609183-NoProjectMatch.svg"
);
export const NoProjectExistImage = getAssetUrl(
  "1730393608584-NoProjectExist.svg"
);
export const NoChatImage = getAssetUrl("1730393607511-NoChat.png");
export const NoBorderRadiousIcon = getAssetUrl(
  "1730393606796-NoBorderRadious.svg"
);
export const MobileScreenIcon = getAssetUrl("1730393605974-MobileScreen.svg");
export const MobileAppIcon = getAssetUrl("1730393605259-MobileApp.svg");
export const MakeItYourIcons = getAssetUrl("1730393604647-MakeItYour.svg");
export const LogoutIcons = getAssetUrl("1730393604050-Logout.svg");
export const LinkedinIcon = getAssetUrl("1730393603550-Linkedin.svg");
export const LanguageIcon = getAssetUrl("1730393603055-LanguageIcon.svg");
export const InputLayersIcon = getAssetUrl("1730393602492-InputLayers.svg");
export const HeadingLayersIcon = getAssetUrl("1730393601879-HeadingLayers.svg");
export const Googleicon = getAssetUrl("1730393601263-Googleicon.svg");
export const GlobalFontIcon = getAssetUrl("1730393600657-GlobalFontImage.svg");
export const FlatWebStyleImage = getAssetUrl("1730393600061-FlatWebStyle.png");
export const FirstBorderRadiousIcon = getAssetUrl(
  "1730393599371-FirstBorderRadious.svg"
);
export const FigmaToAppicon = getAssetUrl("1730393598845-FigmaToAppicon.svg");
export const FeaturesCustimizationIcon = getAssetUrl(
  "1730393597853-FeaturesCustimization.svg"
);
export const FacebookIcon = getAssetUrl("1730393597300-Facebook.svg");
export const ExportMainImageIcons = getAssetUrl(
  "1730393596660-ExportMainImage.svg"
);
export const ExportIcon = getAssetUrl("1730393596041-ExportIcon.svg");
export const ExportBlackIcons = getAssetUrl("1730393595457-ExportBlack.svg");
export const DesktopScreenIcon = getAssetUrl("1730393594298-DesktopScreen.svg");
export const DeployProjectIcon = getAssetUrl(
  "1730393593699-DeployProjectIcon.svg"
);
export const DeployMainImageIcons = getAssetUrl(
  "1730393593186-DeployMainImage.svg"
);
export const DeployBlackIcons = getAssetUrl("1730393592701-DeployBlack.svg");
export const DashedStyleIcon = getAssetUrl("1730393592150-DashedStyle.svg");
export const CodeImageDarkIcon = getAssetUrl("1730393591071-CodeImageDark.svg");
export const CodeImageIcon = getAssetUrl("1730393590550-CodeImage.svg");
export const CircleBorderRadiousIcon = getAssetUrl(
  "1730393590001-CircleBorderRadious.svg"
);
export const CameraIcon = getAssetUrl("1730393589254-CameraIcon.svg");
export const ButtonLayersIcon = getAssetUrl("1730393588785-ButtonLayers.svg");
export const AuthenticationIcon = getAssetUrl(
  "1730393588312-Authentication.svg"
);
export const AuthBackgroundImage = getAssetUrl(
  "1730393587547-AuthBackgroundImage.webp"
);
export const ActiveUserIcon = getAssetUrl("1730393587067-AtiveUser.svg");
export const AIgenerateBtnIcon = getAssetUrl("1730393586429-AIgenerateBtn.svg");
export const AIPrimaryStarIcon = getAssetUrl("1730393585215-AIPrimaryStar.svg");
export const SectionLayersIcon = getAssetUrl("1732787941073-SectionLayers.svg");
export const Purpul3StarIcon = getAssetUrl("1732787940045-Purpul3Star.svg");
export const notFoundImage = getAssetUrl("1732787939468-notFoundImage.svg");
export const SomethingWentImage = getAssetUrl(
  "1732787941539-SomethingWentImage.svg"
);
export const RatingReviewImage = getAssetUrl("1732787940532-ratingReview.svg");
export const LostConnectionImage = getAssetUrl(
  "1732787938461-LostConnection.svg"
);
export const CMSImage = getAssetUrl("1732787930799-Cms.svg");
export const SupportImage = getAssetUrl("1732787942021-Support.svg");
export const LocalLizationImage = getAssetUrl(
  "1732787937943-LocalLization.svg"
);
export const DottedStyledIcon = getAssetUrl("1732787932488-DashedIcon.svg");
export const CircularArrowIcon = getAssetUrl(
  "1732787932004-curcularArrowIcon.svg"
);
export const AutoSaveChangesIcon = getAssetUrl(
  "1732787929781-AutoSaveChanges.svg"
);
export const UnSavedIcons = getAssetUrl("1732787942992-UnSaved.svg");
export const FigmaToAppImage = getAssetUrl(
  "1732787932970-FigmaToAppImage.webp"
);
export const NoCodeAppImage = getAssetUrl("1732787938964-NoCodeAppImage.webp");
export const ImageBGLInesImage = getAssetUrl("1732787933462-ImageBGLInes.svg");
export const UpdatePropmtSendIcon = getAssetUrl(
  "1732787943569-updatePropmtSendIcon.svg"
);
export const GuestProfileImage = getAssetUrl(
  "1732868330260-GuestUserImage.svg"
);
// export const FigmaToAppImage = getAssetUrl(
//   "1730393598343-FigmaToAppImage.webp"
// );
// export const CodetoAppImage = getAssetUrl("1730393591553-CodetoApp.webp");
// export const NoCodeAppImage = getAssetUrl("1730393608022-NoCodeApp.webp");
export const LogoImage = getAssetUrl("1734009192951-fullLogo.svg");
export const PrimaryDoubleTickIcon = getAssetUrl(
  "1734018892657-PrimaryDoubleTick.svg"
);
export const WhiteDoubleTickIcon = getAssetUrl(
  "1734018893916-WhiteDoubleTick.svg"
);
export const TickTemplatesIcons = getAssetUrl(
  "1734018893433-TickTemplates.svg"
);
export const ThumbnailIcod = getAssetUrl("1734018891991-IcodPreviewImage.webp");
export const ForbiddenImage = getAssetUrl("1734018894902-forbiddenImage.svg");
export const WhiteLogoIcon = getAssetUrl("1734018894430-WhiteLogoIcon.svg");
export const profileBGColor = getAssetUrl("1734018906320-profileBGColor.png");
export const UserProfileImage = getAssetUrl("1734018906894-user-profile.png");
export const IntroVedio = getAssetUrl("1734018895372-intro.mp4");
export const CodetoAppImage = getAssetUrl("1734018889985-CodetoAppImage.png");
export const LinkSvgIcon = getAssetUrl("1734529748738-linkSvgIcon.svg");
export const CopyIcon = getAssetUrl("1734529942937-CopyIcon.svg");
export const AICion = getAssetUrl("1734538541313-AiICon.svg");
