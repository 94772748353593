import { createSlice } from "@reduxjs/toolkit";

export interface ChatState {
  messages: any;
}

const initialState: ChatState = {
  messages: [],
};

const chatSlice = createSlice({
  name: "chatbot",
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    loadHistory: (state, action) => {
      state.messages = action.payload;
    },
    clearChat: (state) => {
      state.messages = [];
    },
  },
});

export const { addMessage, loadHistory, clearChat } = chatSlice.actions;
export default chatSlice.reducer;
